import React from 'react';

const Header = () => {
  return (
    <header className="absolute top-0 left-0 right-0 text-white">
      <div className="container mx-auto flex justify-between items-center p-4">
        <div className="text-2xl font-bold">AVATIOS</div>
      </div>
    </header>
  );
}

export default Header;
