import React from 'react';
import heroImage from '../assets/logo.png'; // Ensure you have an image at this path

const Hero = () => {
  return (
    <section className="bg-white-100 text-gray-800">
     <div className="mt-8">
          <img src={heroImage} alt="Fitness Insights" className="w-full max-w-lg mx-auto"/>
        </div>
      <div className="container mx-auto flex flex-col items-center p-6">
        <div className="flex flex-col justify-center items-center text-center">
          <p className="text-xl mb-4"><div id="form-acm_47877"></div></p>
        </div>
      </div>
    </section>
  );
}

export default Hero;
